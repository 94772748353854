const translation = {
  feedback: {
    title: 'Com’è stata la tua esperienza di supporto?',
    unsatisfied: 'Cattivo',
    satisfied: 'Buono',
    stepTwoTitle: 'Grazie per il tuo feedback! Potresti condividere di più per aiutarci a migliorare?',
    submit: 'Invia',
    finish: 'Capito! Sei molto gentile! Continueremo a lavorare per migliorare le cose.',
    placeholder: 'Commenta qui...',
    commentCount: 'Le recensioni di supporto devono essere inferiori a 4096 caratteri.',
  },
}

export default translation
