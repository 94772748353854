const translation = {
  feedback: {
    title: 'Jakie są Twoje doświadczenia z pomocą techniczną?',
    unsatisfied: 'Zły',
    satisfied: 'Dobry',
    stepTwoTitle: 'Dziękujemy za opinię! Czy możesz podzielić się więcej, aby pomóc nam się poprawić?',
    submit: 'Zatwierdź',
    finish: 'Zrozumiano! To miłe z twojej strony! Będziemy nadal pracować nad poprawą.',
    placeholder: 'Komentarz tutaj...',
    commentCount: 'Recenzje wsparcia powinny mieć mniej niż 4096 znaków.',
  },
}

export default translation
