const translation = {
  feedback: {
    title: '您的支持體驗如何？',
    unsatisfied: '不好',
    satisfied: '滿意',
    stepTwoTitle: '感謝您的反饋！ 您能分享更多以幫助我們改進嗎？',
    submit: '提交',
    finish: '明白了！ 您真好！ 我們會繼續努力改進。',
    placeholder: '在這裡評論...',
    commentCount: '支援評論應少於4096個字符。',
  },
}

export default translation
