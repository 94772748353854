const translation = {
  feedback: {
    title: 'Wie war Ihre Unterstützungserfahrung?',
    unsatisfied: 'Schlecht',
    satisfied: 'Gut',
    stepTwoTitle: 'Danke für Ihr Feedback! Könnten Sie mehr teilen, um uns zu helfen, uns zu verbessern?',
    submit: 'Einreichen',
    finish: 'Verstanden! Das ist nett von Ihnen! Wir werden weiter daran arbeiten, die Dinge zu verbessern.',
    placeholder: 'Hier kommentieren...',
    commentCount: 'Unterstützungsbewertungen sollten weniger als 4096 Zeichen umfassen.',
  },
}

export default translation
