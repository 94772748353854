const translation = {
  feedback: {
    title: 'サポート体験はいかがでしたか？',
    unsatisfied: '悪い',
    satisfied: '良い',
    stepTwoTitle: 'フィードバックありがとうございます！ 改善のためにもっと教えていただけますか？',
    submit: '送信',
    finish: 'わかりました！ ご親切にありがとうございます！ 私たちは改善に向けて努力し続けます。',
    placeholder: 'ここにコメント...',
    commentCount: 'サポートレビューは4096文字未満である必要があります。',
  },
}

export default translation
