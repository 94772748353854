const translation = {
  feedback: {
    title: 'Каков ваш опыт поддержки?',
    unsatisfied: 'Плохо',
    satisfied: 'Хорошо',
    stepTwoTitle: 'Спасибо за ваш отзыв! Могли бы вы поделиться более подробной информацией, чтобы помочь нам улучшиться?',
    submit: 'Отправить',
    finish: 'Понял! Это очень мило с вашей стороны! Мы будем продолжать работать над улучшением.',
    placeholder: 'Оставьте комментарий здесь...',
    commentCount: 'Отзывы поддержки должны содержать менее 4096 символов.',
  },
}

export default translation
