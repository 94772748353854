const translation = {
  feedback: {
    title: 'Comment s’est passée votre expérience de support ?',
    unsatisfied: 'Mauvais',
    satisfied: 'Bon',
    stepTwoTitle: 'Merci pour votre retour ! Pourriez-vous en dire plus pour nous aider à nous améliorer ?',
    submit: 'Soumettre',
    finish: 'Compris ! C’est gentil de votre part ! Nous continuerons à travailler pour améliorer les choses.',
    placeholder: 'Commentez ici...',
    commentCount: 'Les avis de support doivent comporter moins de 4096 caractères.',
  },
}

export default translation
