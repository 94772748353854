const translation = {
  feedback: {
    title: 'How’s your support experience?',
    unsatisfied: 'Bad',
    satisfied: 'Good',
    stepTwoTitle: 'Thanks for your feedback! Could you share more to help us improve?',
    submit: 'Submit',
    finish: 'Got it! That\'s nice of you! We\'ll keep working to make things better.',
    placeholder: 'Comment here...',
    commentCount: 'Support reviews should be less than 4096 characters.',
  },
}

export default translation
