const translation = {
  feedback: {
    title: 'Como foi sua experiência de suporte?',
    unsatisfied: 'Ruim',
    satisfied: 'Bom',
    stepTwoTitle: 'Obrigado pelo seu feedback! Você poderia compartilhar mais para nos ajudar a melhorar?',
    submit: 'Enviar',
    finish: 'Entendi! Que gentil da sua parte! Continuaremos trabalhando para melhorar as coisas.',
    placeholder: 'Comente aqui...',
    commentCount: 'As avaliações de suporte devem ter menos de 4096 caracteres.',
  },
}

export default translation
