const translation = {
  welcome: {
    firstStepTip: '开始之前,',
    enterKeyTip: '请先在下方输入你的 OpenAI API Key',
    getKeyTip: '从 OpenAI 获取你的 API Key',
    placeholder: '你的 OpenAI API Key（例如 sk-xxxx）',
  },
  apiKeyInfo: {
    cloud: {
      trial: {
        title: '您正在使用 {{providerName}} 的试用配额。',
        description: '试用配额仅供您测试使用。 在试用配额用完之前，请自行设置模型提供商或购买额外配额。',
      },
      exhausted: {
        title: '您的试用额度已用完，请设置您的APIKey。',
        description: '您的试用配额已用完。 请设置您自己的模型提供商或购买额外配额。',
      },
    },
    selfHost: {
      title: {
        row1: '首先，',
        row2: '设置您的模型提供商。',
      },
    },
    callTimes: '调用次数',
    usedToken: '使用 Tokens',
    setAPIBtn: '设置模型提供商',
    tryCloud: '或者尝试使用 Dify 的云版本并使用试用配额',
  },
  overview: {
    title: '概览',
    appInfo: {
      explanation: '开箱即用的 AI WebApp',
      accessibleAddress: '公开访问 URL',
      preview: '预览',
      regenerate: '重新生成',
      regenerateNotice: '您是否要重新生成公开访问 URL？',
      preUseReminder: '使用前请先打开开关',
      settings: {
        entry: '设置',
        title: '设置',
        webName: '名称',
        webDesc: 'WebApp 描述',
        type: '类型',
        webDescTip: '以下文字将展示在客户端中，对应用进行说明和使用上的基本引导',
        webDescPlaceholder: '请输入 WebApp 的描述',
        language: '语言',
        workflow: {
          title: '工作流详情',
          show: '显示',
          hide: '隐藏',
        },
        chatColorTheme: '聊天颜色主题',
        chatColorThemeDesc: '设置聊天机器人的颜色主题',
        chatColorThemeInverted: '反转',
        invalidHexMessage: '无效的十六进制值',
        more: {
          entry: '展示更多设置',
          copyright: '版权',
          copyRightPlaceholder: '请输入作者或组织名称',
          privacyPolicy: '隐私政策',
          privacyPolicyPlaceholder: '请输入隐私政策链接',
          privacyPolicyTip: '帮助访问者了解该应用收集的数据，可参考 Dify 的<privacyPolicyLink>隐私政策</privacyPolicyLink>。',
          customDisclaimer: '自定义免责声明',
          customDisclaimerPlaceholder: '请输入免责声明',
          customDisclaimerTip: '在应用中展示免责声明，可用于告知用户 AI 的局限性。',
        },
        website: '允许使用的网站',
        webSitePlaceholder: '填写后，仅填写的网站能使用此AI Bot；不填则任何网站可用',
        webSiteValidateError: '请填写正确的一级域名网址',
        webLogo: '图标',
        webThemeColor: '主题色',
        buttonShape: {
          label: '按钮形状',
          size: '大小',
          radius: '圆角',
        },
        buttonPosition: {
          label: '按钮位置',
          position: '布局',
          xGap: '侧边距离',
          bottom: '底部距离',
          pc: '电脑',
          mobile: '手机',
          left: '居左',
          right: '居右',
        },
      },
      embedded: {
        entry: '嵌入',
        title: '嵌入到网站中',
        explanation: '选择一种方式将聊天应用嵌入到你的网站中',
        iframe: '将以下 iframe 嵌入到你的网站中的目标位置',
        scripts: '将以下代码嵌入到你的网站中',
        chromePlugin: '安装 Dify Chrome 浏览器扩展',
        copied: '已复制',
        copy: '复制',
      },
      qrcode: {
        title: '二维码分享',
        scan: '扫码分享应用',
        download: '下载二维码',
      },
      customize: {
        way: '方法',
        entry: '定制化',
        title: '定制化 AI WebApp',
        explanation: '你可以定制化 Web App 前端以符合你的情景与风格需求',
        way1: {
          name: 'Fork 客户端代码修改后部署到 Vercel（推荐）',
          step1: 'Fork 客户端代码并修改',
          step1Tip: '点击此处 Fork 源码到你的 GitHub 中，然后修改代码',
          step1Operation: 'Dify-WebClient',
          step2: '部署到 Vercel 中',
          step2Tip: '点击此处将仓库导入到 Vercel 中部署',
          step2Operation: '导入仓库',
          step3: '配置环境变量',
          step3Tip: '在 Vecel 环境变量中添加以下环境变量',
        },
        way2: {
          name: '编写客户端调用 API 并部署到服务器中',
          operation: '查看文档',
        },
      },
    },
    apiInfo: {
      title: '后端服务 API',
      explanation: '可集成至你的应用的后端即服务',
      accessibleAddress: 'API 访问凭据',
      doc: '查阅 API 文档',
    },
    status: {
      running: '运行中',
      disable: '已停用',
    },
  },
  analysis: {
    title: '分析',
    ms: '毫秒',
    tokenPS: 'Token/秒',
    totalMessages: {
      title: '全部消息数',
      explanation: 'AI每天回答的消息总数。',
    },
    activeUsers: {
      title: '咨询用户数',
      explanation: '有一问一答以上的用户数。',
    },
    tokenUsage: {
      title: '费用消耗',
      explanation: '反映每日该应用请求语言模型的 Tokens 花费，用于成本控制。',
      consumed: '耗费',
    },
    avgSessionInteractions: {
      titleOld: '对话平均消息数',
      explanationOld: '每次对话AI回答消息数的平均值。',
      title: '会话平均消息数',
      explanation: '每个会话的平均消息数',
    },
    avgUserInteractions: {
      title: '平均用户调用次数',
      explanation: '反应每天用户的使用次数。该指标反映了用户粘性。',
    },
    userSatisfactionRate: {
      title: 'AI 解决率',
      explanationOld: 'AI解决的问题占所有问题的比例，每条用户消息算一个问题。\n用户反馈不满意/缺少知识的问答，均被统计为未解决。',
      explanation: 'AI解决的消息量占总消息数的百分比，计算公式为(消息数 - 负反馈点评消息数 - 缺知识回答消息数) / 消息数 * 100%。',
      actionLeft: '可提升，',
      actionRight: '前往优化',
    },
    avgResponseTime: {
      title: '平均响应时间',
      explanation: '衡量 AI 应用处理和回复用户请求所花费的平均时间，单位为毫秒，反映性能和用户体验。仅在文本型应用提供。',
    },
    tps: {
      title: 'Token 输出速度',
      explanation: '衡量 LLM 的性能。统计 LLM 从请求开始到输出完毕这段期间的 Tokens 输出速度。',
    },
    aiServiceProfile: {
      title: 'AI 服务概况',
      interactionAIUserCount: {
        title: '与AI互动的用户数',
        explanation: 'Measure the performance of the LLM. Count the Tokens output speed of LLM from the beginning of the request to the completion of the output.',
        open_window_users: '打开AI',
        chat_users: '与AI聊天',
        open_window_users_explanation: '打开聊天窗口的用户量',
        chat_users_explanation: '与AI发生至少一次对话的用户数',
      },
      conversationAndMessageCount: {
        title: '会话数与消息数',
        explanation: '与AI发生对话的会话数量 & 与AI对话的消息数量',
        conversations: '会话数',
        messages: '消息数',
        conversations_explanation: '与AI发生对话的会话数量',
        messages_explanation: '与AI对话的消息数量',
      },
      avgChatDuration: {
        title: '会话平均聊天时长',
        explanation: '每个会话的平均聊天时长',
      },
      timeGranularity: {
        hour: '按时',
        day: '按天',
        month: '按月',
      },
    },
    aiRecoverPerformance: {
      title: 'AI 服务表现',
      laborTransferRate: {
        title: 'AI 转人工率',
        explanation: '转接到人工的会话占总会话数的百分比',
        unforwarded: '未转接人工的会话数',
        forwarded: '转接人工的会话数',
      },
      aiReplySatisfaction: {
        title: 'AI 回复满意度',
        explanation: '用户对会话的满意度分布',
      },
      userFeedbackDistribution: {
        title: '用户反馈分布',
        explanation: '用户反馈的消息分布比例',
      },
    },
    userConsultingInsights: {
      title: '用户咨询洞察',
      consultationScenarioDistribution: {
        title: '咨询场景分布',
        explanation: '用户咨询的场景分布的会话/消息分布比例',
      },
      consultationIntentionDistribution: {
        title: '咨询意图分布',
        explanation: '用户咨询的意图分布的会话/消息分布比例',
      },
      consultationProductDistribution: {
        title: '咨询商品Top20',
        explanation: '用户咨询的Top20商品的会话/消息分布比例',
      },
    },
    userToHumanInsights: {
      title: '用户转人工洞察',
      userCountDistribution: {
        title: '转人工会话数',
        explanation: '用户点击转接人工按钮的会话数 & 成功转接至人工服务的会话数',
        click_count_explanation: '用户点击转接人工按钮的会话数',
        submit_count_explanation: '成功转接至人工服务的会话数',
        click_count: '点击转人工',
        submit_count: '成功转接人工',
      },
      reasonDistribution: {
        title: '点击转人工原因分布',
        explanation: '点击转接人工原因的会话分布比例',
      },
      channelDistribution: {
        title: '转接至人工的渠道分布',
        explanation: '转接至人工客服的会话在不同渠道上的分布比例',
      },
    },
    lackKnowledgeInsights: {
      title: 'AI 缺少知识洞察',
      lackKnowledgeRate: {
        title: 'AI 缺少知识比例',
        explanation: 'AI缺少知识时的消息占总消息的比例',
      },
      lackKnowledgeIntentionDistribution: {
        title: '缺少知识意图分布',
        explanation: 'AI缺少知识回复的消息中，用户咨询的意图消息分布比例',
      },
      lackKnowledgeProductDistribution: {
        title: '缺少知识商品Top20',
        explanation: 'AI缺少知识回复的消息中，用户咨询的Top20商品的消息分布比例',
      },
    },
    shopifyProductCardInsights: {
      title: 'AI 推荐商品表现',
      commodityCardConversionFunnel: {
        title: 'AI 商品推荐转化',
        explanation: '收到AI推荐商品和点击商品的用户数',
        push: '推荐用户数',
        click: '点击用户数',
      },
    },
    tagFilters: {
      conversation: '会话维度',
      message: '消息维度',
      allScene: '全部场景',
      allSku: '全部产品',
      allIntent: '全部意图',
      preSales: 'Pre-Sales', // 售前售后先不需要多语言
      afterSales: 'Post-Sales',
      selectSku: '选择产品',
      selectIntent: '选择意图',
    },
    export: '导出数据明细',
    fileName: '数据明细',
    exportLimitTip: '导出时间范围不能超过31天',
  },
}

export default translation
