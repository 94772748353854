const translation = {
  title: '标注',
  name: '标注回复',
  editBy: '{{author}}编辑的答案',
  noData: {
    title: '没有标注',
    description: '你可以在应用会话调试中编辑标注，也可以在此批量导入标注用于高质量回复。',
  },
  table: {
    header: {
      question: '提问',
      match: '匹配',
      response: '回复',
      answer: '答案',
      createdAt: '创建时间',
      hits: '命中次数',
      actions: '操作',
      addAnnotation: '添加标注',
      bulkImport: '批量导入',
      bulkExport: '批量导出',
      clearAll: '删除所有标注',
    },
  },
  editModal: {
    title: '编辑标注回复',
    queryName: '问题',
    answerName: '回复',
    yourAnswer: '您的回复',
    answerPlaceholder: '在这里输入您的回复',
    yourQuery: '您的提问',
    queryPlaceholder: '在这里输入您的提问',
    removeThisCache: '删除此标注',
    createdAt: '创建于',
  },
  addModal: {
    title: '添加标注回复',
    queryName: '提问',
    answerName: '回复',
    answerPlaceholder: '输入回复',
    queryPlaceholder: '输入提问',
    createNext: '添加下一个标注回复',
  },
  batchModal: {
    title: '批量导入',
    csvUploadTitle: '将您的 CSV 文件拖放到此处，或',
    browse: '选择文件',
    tip: 'CSV 文件必须符合以下结构：',
    question: '问题',
    answer: '回答',
    contentTitle: '分段内容',
    content: '内容',
    template: '下载模板',
    cancel: '取消',
    run: '导入',
    runError: '批量导入失败',
    processing: '批量处理中',
    completed: '导入完成',
    error: '导入出错',
    ok: '确定',
  },
  errorMessage: {
    answerRequired: '回复不能为空',
    queryRequired: '提问不能为空',
  },
  viewModal: {
    annotatedResponse: '标注回复',
    hitHistory: '命中历史',
    hit: '次命中',
    hits: '次命中',
    noHitHistory: '暂无使用记录',
  },
  hitHistoryTable: {
    question: '问题',
    query: '提问',
    match: '匹配',
    response: 'AI 回复',
    source: '来源',
    score: '分数',
    time: '时间',
  },
  initSetup: {
    title: '标注回复初始设置',
    configTitle: '标注回复设置',
    confirmBtn: '保存并启用',
    configConfirmBtn: '保存',
  },
  embeddingModelSwitchTip: '标注文本向量化模型，切换模型会重新嵌入，产生额外费用消耗',
  original: '原始提问',
  qaDetail: '问答详情',
  export: '导出',
}

export default translation
