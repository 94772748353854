const translation = {
  feedback: {
    title: '您的支持体验如何？',
    unsatisfied: '不好',
    satisfied: '满意',
    stepTwoTitle: '感谢您的反馈！ 您能分享更多以帮助我们改进吗？',
    submit: '提交',
    finish: '明白了！ 您真好！ 我们会继续努力改进。',
    placeholder: '在这里评论...',
    commentCount: '支持体验评论请少于4096个字符',
  },
}

export default translation
