const translation = {
  title: '对话记录',
  description: '记录了AI Bot的对话明细，包括用户的输入和AI的回复。',
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  table: {
    header: {
      updatedTime: '更新时间',
      time: '创建时间',
      endUser: '用户',
      input: '输入',
      output: '输出',
      summary: '标题',
      messageCount: '消息数',
      userRate: '用户反馈',
      adminRate: '管理员反馈',
      startTime: '开始时间',
      status: '状态',
      runtime: '运行时间',
      tokens: 'TOKENS',
      user: '用户',
      version: '版本',
      name: '姓名',
    },
    pagination: {
      previous: '上一页',
      next: '下一页',
    },
    empty: {
      noChat: '未开始的对话',
      noOutput: '无输出',
      element: {
        title: '这里有人吗',
        content: '在这里查看用户和AI的对话，以不断提高 AI 的准确性。您可以<testLink>试试</testLink> WebApp 或<shareLink>分享</shareLink>出去，然后返回此页面。',
      },
    },
  },
  detail: {
    time: '时间',
    conversationId: '对话 ID',
    promptTemplate: '前缀提示词',
    promptTemplateBeforeChat: '对话前提示词 · 以系统消息提交',
    annotationTip: '{{user}} 标记的改进回复',
    timeConsuming: '耗时',
    second: ' 秒',
    tokenCost: '花费 Token',
    loading: '加载中',
    operation: {
      like: '有帮助',
      dislike: '不满意',
      addAnnotation: '标记改进回复',
      editAnnotation: '编辑改进回复',
      annotationPlaceholder: '输入你希望 AI 回复的预期答案，这在今后可用于模型微调，持续改进文本生成质量。',
    },
    variables: '变量',
    uploadImages: '上传的图片',
  },
  filter: {
    period: {
      today: '今天',
      last7days: '过去 7 天',
      last4weeks: '过去 4 周',
      last3months: '过去 3 月',
      last12months: '过去 12 月',
      monthToDate: '本月至今',
      quarterToDate: '本季度至今',
      yearToDate: '本年至今',
      allTime: '所有时间',
    },
    annotation: {
      all: '全部',
      annotated: '已标注改进（{{count}} 项）',
      not_annotated: '未标注',
    },
    sortBy: '排序：',
    descending: '降序',
    ascending: '升序',
    export: '导出 AI 对话明细',
  },
  workflowTitle: '日志',
  workflowSubtitle: '日志记录了应用的执行情况',
  runDetail: {
    title: '对话日志',
    workflowTitle: '日志详情',
  },
  promptLog: 'Prompt 日志',
  agentLog: 'Agent 日志',
  viewLog: '查看日志',
  agentLogDetail: {
    agentMode: 'Agent 模式',
    toolUsed: '使用工具',
    iterations: '迭代次数',
    iteration: '迭代',
    finalProcessing: '最终处理',
  },
  answerTrace: {
    title: '回答参考以下知识',
    loading: '正在查找 AI 引用的知识',
    view: '查看 AI 引用知识',
  },
}

export default translation
