const translation = {
  feedback: {
    title: '지원 경험은 어땠나요?',
    unsatisfied: '나쁨',
    satisfied: '좋음',
    stepTwoTitle: '피드백 감사합니다! 개선을 위해 더 많은 정보를 공유해 주시겠습니까?',
    submit: '제출',
    finish: '알겠습니다! 친절하시네요! 우리는 더 나은 서비스를 위해 계속 노력하겠습니다.',
    placeholder: '여기에 댓글을 남겨주세요...',
    commentCount: '지원 리뷰는 4096자 미만이어야 합니다.',
  },
}

export default translation
