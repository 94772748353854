const translation = {
  list: {
    title: 'Documents',
    desc: 'All files of the Knowledge are shown here, and the entire Knowledge can be linked to Dify citations or indexed via the Chat plugin.',
    addFile: 'Add file',
    addVideo: 'Add video',
    addWebsite: 'Add website',
    addPages: 'Add Pages',
    addUrl: 'Add URL',
    table: {
      header: {
        fileName: 'File Name',
        websiteName: 'Website',
        words: 'Character Count',
        hitCount: 'AI Usage Count',
        uploadTime: 'Upload Time',
        status: 'Status',
        action: 'Action',
      },
      rename: 'Rename',
      name: 'Name',
      updatedAt: 'Update Time',
    },
    action: {
      uploadFile: 'Upload New File',
      settings: 'Segmentation Settings',
      addButton: 'Add Segment',
      add: 'Add New Segment',
      batchAdd: 'Batch Add',
      archive: 'Archive',
      unarchive: 'Unarchive',
      delete: 'Delete',
      enableWarning: 'Archived files cannot be enabled',
      sync: 'Update',
    },
    index: {
      enable: 'Enabled',
      disable: 'Disabled',
      all: 'All',
      enableTip: 'This file can be indexed',
      disableTip: 'This file cannot be indexed',
    },
    status: {
      queuing: 'Queuing',
      indexing: 'AI Rapid Learning',
      paused: 'Paused',
      error: 'Error',
      available: 'Available',
      enabled: 'Enabled',
      disabled: 'Disabled',
      archived: 'Archived',
      deep_indexing: 'AI Deep Learning',
    },
    empty: {
      title: 'No Documents Yet',
      videoTitle: 'No Videos Yet',
      websiteTitle: 'No Websites Yet',
      upload: {
        tip: 'Add documents now to let the AI Bot answer based on documents (supports uploading MARKDOWN, PDF, DOC, TXT, XLS, CSV formats).',
      },
      sync: {
        tip: 'Dify will regularly download and process files from your Notion.',
      },
      video: {
        tip: 'Add videos now to let the AI Bot use them in responses (supports adding Bilibili/YouTube video links).',
      },
      crawl: {
        tip: 'Add websites now to let the AI Bot use them in responses',
      },
    },
    delete: {
      title: 'Are You Sure You Want to Delete?',
      content: 'This action cannot be undone, and the AI Bot will not be able to reference this knowledge.',
    },
    batchModal: {
      title: 'Batch Add Segments',
      csvUploadTitle: 'Drop your CSV file here, or',
      browse: 'Select File',
      tip: 'CSV file structure should be as follows:',
      question: 'Question',
      answer: 'Answer',
      contentTitle: 'Segment Content',
      content: 'Content',
      template: 'Download Template',
      cancel: 'Cancel',
      run: 'Import',
      runError: 'Batch Import Failed',
      processing: 'Processing Batch',
      completed: 'Import Completed',
      error: 'Import Error',
      ok: 'OK',
    },
    docError: {
      unAccess: 'Webpage is not accessible',
      error: 'Network error',
    },
  },
  metadata: {
    title: 'Metadata',
    desc: 'Tagging the metadata of documents allows AI to access them promptly and surface references to users.',
    dateTimeFormat: 'YYYY-MM-DD HH:mm',
    docTypeSelectTitle: 'Please Choose a Document Type',
    docTypeChangeTitle: 'Change Document Type',
    docTypeSelectWarning: 'Changing the document type will discard current metadata entries.',
    firstMetaAction: 'Start',
    placeholder: {
      add: 'Enter',
      select: 'Select',
    },
    source: {
      upload_file: 'File Upload',
      notion: 'Synced from Notion',
      github: 'Synced from Github',
    },
    type: {
      book: 'Book',
      webPage: 'Web Page',
      paper: 'Paper',
      socialMediaPost: 'Social Media Post',
      personalDocument: 'Personal Document',
      businessDocument: 'Business Document',
      IMChat: 'IM Chat Record',
      wikipediaEntry: 'Wikipedia Entry',
      notion: 'Synced from Notion',
      github: 'Synced from Github',
      technicalParameters: 'Technical Parameters',
      pdf: 'PDF',
    },
    field: {
      processRule: {
        processDoc: 'Preprocess Document',
        segmentRule: 'Segmentation Rule',
        segmentLength: 'Segment Length',
        processClean: 'Text Preprocessing and Cleaning',
      },
      book: {
        title: 'Title',
        language: 'Language',
        author: 'Author',
        publisher: 'Publisher',
        publicationDate: 'Publication Date',
        ISBN: 'ISBN',
        category: 'Category',
      },
      webPage: {
        title: 'Title',
        url: 'URL',
        language: 'Language',
        authorPublisher: 'Author/Publisher',
        publishDate: 'Publish Date',
        topicsKeywords: 'Topics/Keywords',
        description: 'Description',
      },
      paper: {
        title: 'Title',
        language: 'Language',
        author: 'Author',
        publishDate: 'Publish Date',
        journalConferenceName: 'Journal/Conference Name',
        volumeIssuePage: 'Volume/Issue/Page',
        DOI: 'DOI',
        topicsKeywords: 'Topics/Keywords',
        abstract: 'Abstract',
      },
      socialMediaPost: {
        platform: 'Platform',
        authorUsername: 'Author/Username',
        publishDate: 'Publish Date',
        postURL: 'Post URL',
        topicsTags: 'Topics/Tags',
      },
      personalDocument: {
        title: 'Title',
        author: 'Author',
        creationDate: 'Creation Date',
        lastModifiedDate: 'Last Modified Date',
        documentType: 'Document Type',
        tagsCategory: 'Tags/Category',
      },
      businessDocument: {
        title: 'Title',
        author: 'Author',
        creationDate: 'Creation Date',
        lastModifiedDate: 'Last Modified Date',
        documentType: 'Document Type',
        departmentTeam: 'Department/Team',
      },
      IMChat: {
        chatPlatform: 'Chat Platform',
        chatPartiesGroupName: 'Chat Parties/Group Name',
        participants: 'Participants',
        startDate: 'Start Date',
        endDate: 'End Date',
        topicsKeywords: 'Topics/Keywords',
        fileType: 'File Type',
      },
      pdf: {
        title: 'Title',
        author: 'Author',
        creationDate: 'Creation Date',
        lastModifiedDate: 'Last Modified Date',
        documentType: 'Document Type',
        tagsCategory: 'Tags/Category',
        url: 'URL',
      },
      wikipediaEntry: {
        title: 'Title',
        language: 'Language',
        webpageURL: 'Web Page URL',
        editorContributor: 'Editor/Contributor',
        lastEditDate: 'Last Edit Date',
        summaryIntroduction: 'Summary/Introduction',
      },
      notion: {
        title: 'Title',
        language: 'Language',
        author: 'Author',
        createdTime: 'Created Time',
        lastModifiedTime: 'Last Modified Time',
        url: 'URL',
        tag: 'Tag',
        description: 'Description',
      },
      github: {
        repoName: 'Repository Name',
        repoDesc: 'Repository Description',
        repoOwner: 'Repository Owner',
        fileName: 'File Name',
        filePath: 'File Path',
        programmingLang: 'Programming Language',
        url: 'URL',
        license: 'License',
        lastCommitTime: 'Last Commit Time',
        lastCommitAuthor: 'Last Commit Author',
      },
      originInfo: {
        originalFilename: 'Original File Name',
        originalFileSize: 'Original File Size',
        uploadDate: 'Upload Date',
        lastUpdateDate: 'Last Update Date',
        source: 'Source',
      },
      technicalParameters: {
        segmentSpecification: 'Segmentation Rule',
        segmentLength: 'Paragraph Length',
        avgParagraphLength: 'Average Paragraph Length',
        paragraphs: 'Number of Paragraphs',
        hitCount: 'Recall Count',
        embeddingTime: 'Embedding Time',
        embeddedSpend: 'Embedding Cost',
      },
    },
    languageMap: {
      zh: 'Chinese',
      en: 'English',
      es: 'Spanish',
      fr: 'French',
      de: 'German',
      ja: 'Japanese',
      ko: 'Korean',
      ru: 'Russian',
      ar: 'Arabic',
      pt: 'Portuguese',
      it: 'Italian',
      nl: 'Dutch',
      pl: 'Polish',
      sv: 'Swedish',
      tr: 'Turkish',
      he: 'Hebrew',
      hi: 'Hindi',
      da: 'Danish',
      fi: 'Finnish',
      no: 'Norwegian',
      hu: 'Hungarian',
      el: 'Greek',
      cs: 'Czech',
      th: 'Thai',
      id: 'Indonesian',
    },
    categoryMap: {
      book: {
        fiction: 'Fiction',
        biography: 'Biography',
        history: 'History',
        science: 'Science',
        technology: 'Technology',
        education: 'Education',
        philosophy: 'Philosophy',
        religion: 'Religion',
        socialSciences: 'Social Sciences',
        art: 'Art',
        travel: 'Travel',
        health: 'Health',
        selfHelp: 'Self-Help',
        businessEconomics: 'Business/Economics',
        cooking: 'Cooking',
        childrenYoungAdults: 'Children/Young Adults',
        comicsGraphicNovels: 'Comics/Graphic Novels',
        poetry: 'Poetry',
        drama: 'Drama',
        other: 'Other',
      },
      personalDoc: {
        notes: 'Notes',
        blogDraft: 'Blog Draft',
        diary: 'Diary',
        researchReport: 'Research Report',
        bookExcerpt: 'Book Excerpt',
        schedule: 'Schedule',
        list: 'List',
        projectOverview: 'Project Overview',
        photoCollection: 'Photo Collection',
        creativeWriting: 'Creative Writing',
        codeSnippet: 'Code Snippet',
        designDraft: 'Design Draft',
        personalResume: 'Personal Resume',
        other: 'Other',
      },
      businessDoc: {
        meetingMinutes: 'Meeting Minutes',
        researchReport: 'Research Report',
        proposal: 'Proposal',
        employeeHandbook: 'Employee Handbook',
        trainingMaterials: 'Training Materials',
        requirementsDocument: 'Requirements Document',
        designDocument: 'Design Document',
        productSpecification: 'Product Specification',
        financialReport: 'Financial Report',
        marketAnalysis: 'Market Analysis',
        projectPlan: 'Project Plan',
        teamStructure: 'Team Structure',
        policiesProcedures: 'Policies and Procedures',
        contractsAgreements: 'Contracts and Agreements',
        emailCorrespondence: 'Email Correspondence',
        other: 'Other',
      },
    },
  },
  embedding: {
    processing: 'AI Learning...',
    paused: 'AI Learning Paused',
    completed: 'AI Learning Completed',
    error: 'AI Learning Error',
    docName: 'Preprocessing Document',
    mode: 'Segmentation Rules',
    segmentLength: 'Segment Length',
    textCleaning: 'Text Preprocessing and Cleaning',
    segments: 'Paragraphs',
    highQuality: 'High-Quality Mode',
    economy: 'Economy Mode',
    estimate: 'Estimated Consumption',
    stop: 'Stop Processing',
    resume: 'Resume Processing',
    automatic: 'Automatic',
    custom: 'Custom',
    previewTip: 'Paragraph preview will be available after embedding completion',
  },
  segment: {
    paragraphs: 'Paragraphs',
    keywords: 'Keywords',
    addKeyWord: 'Add Keyword',
    keywordError: 'Keyword max length is 20',
    characters: 'Characters',
    hitCount: 'Recall Count',
    vectorHash: 'Vector Hash: ',
    questionPlaceholder: 'Add Question Here',
    questionEmpty: 'Question cannot be empty',
    answerPlaceholder: 'Add Answer Here',
    answerEmpty: 'Answer cannot be empty',
    contentPlaceholder: 'Add Content Here',
    contentEmpty: 'Content cannot be empty',
    newTextSegment: 'New Text Segment',
    newQaSegment: 'New QA Segment',
    delete: 'Delete This Segment?',
    videoUrl: 'Video Link',
  },
}

export default translation
