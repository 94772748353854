const translation = {
  feedback: {
    title: '¿Cómo fue tu experiencia de soporte?',
    unsatisfied: 'Malo',
    satisfied: 'Bueno',
    stepTwoTitle: '¡Gracias por tus comentarios! ¿Podrías compartir más para ayudarnos a mejorar?',
    submit: 'Enviar',
    finish: '¡Entendido! ¡Qué amable de tu parte! Seguiremos trabajando para mejorar las cosas.',
    placeholder: 'Comenta aquí...',
    commentCount: 'Las reseñas de soporte deben tener menos de 4096 caracteres.',
  },
}

export default translation
